const stage1 = {
    env: "stage1",
    user: [{userid:"test", password:"test"}, {userid:"user2@diro.io", password:"Diro@123"}],
    cookieExpTime: 3600000,
}

const stage2 = {
    env: "stage2",
    user: [{userid:"user1@diro.io", password:"Diro@123"}, {userid:"user2@diro.io", password:"Diro@123"}],
    cookieExpTime: 3600000,
}

const prodeu = {
    env: "prodeu",
    user: [{userid:"user1@diro.io", password:"Diro@123"}, {userid:"user2@diro.io", password:"Diro@123"}],
    cookieExpTime: 3600000,
}
export { stage1 as env };
export { prodeu };
export { stage2 };