import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {env as environment} from '../controller/environment';
import { Context } from "../context";

const Home = (props) => {
    const {
		setUserDetail
	} = useContext(Context);
    const navigate = useNavigate();
    const [validate, setValidate] = useState({
        userid: null,
        password: null,
        error: {
            user: null,
            pass: null,
        }
    });
    const userIdChange = (e) => {
        console.log(e.target.value);
        setValidate({...validate, userid: e.target.value,error: {
            user: null,
            pass: null,
        },});
    }
    const passwordChange = (e) => {
        console.log(e.target.value);
        setValidate({...validate, password: e.target.value,error: {
            user: null,
            pass: null,
        },});
    }
    const submitForm = (e) => {
        e.preventDefault();
        console.log("tst", validate);
        if(!validate.userid || validate.userid === null) {
            setValidate({...validate, error: {pass: null, user: "Please enter User ID"}});
        } else if(!validate.password || validate.password === null) {
            setValidate({...validate, error: {pass: "Please enter Password", user: null}});
        } else {
            setValidate({...validate, error: {pass: null, user: null}});
            const data = { "key": validate.userid, timestamp: Date.now() + environment.cookieExpTime }; // 3600000 ms = 1 hour
            setUserDetail(data);
            localStorage.setItem('utils_user', JSON.stringify(data));
            navigate("/dashboard");
            // let checkUser = environment.user.filter((user) => {
            //     return user.userid === validate.userid;
            // });
            // if(checkUser.length) {
            //     if(checkUser[0].password == validate.password) {
            //         console.log("User found");
            //         setValidate({...validate, error: {pass: null, user: null}});
            //         const data = { "key": validate.userid, timestamp: Date.now() + environment.cookieExpTime }; // 3600000 ms = 1 hour
            //         setUserDetail(data);
            //         localStorage.setItem('utils_user', JSON.stringify(data));
            //         navigate("/dashboard");
            //     } else {
            //         setValidate({...validate, error: {pass: "Check your credentials", user: null}});
            //     }
            // } else {
            //     setValidate({...validate, error: {pass: "Check your credentials", user: null}});
            // }
            console.log(validate.password);
        }
        
        // console.log(environment.user, validate, " Form submit");
        // document.cookie = `referral_key=hello;max-age=604800;domain=example.com`
    }
    return (
        <div>
            <div>
                <input type="hidden" id="GACANumber" />
                <input type="hidden" id="BusinessUserId" />
                <input type="hidden" defaultValue="Guest User" id="login_status" />
                <div className="custom-loader loader-wrap page-loader" style={{display: 'none'}}>
                <div className="custom-loader-box-wrapper">
                    <span className="custom-loader-dots-box">
                    <span className="custom-loader-dot custom-loader-dot-1" />
                    <span className="custom-loader-dot custom-loader-dot-2" />
                    <span className="custom-loader-dot custom-loader-dot-3" /></span>
                    <span className="custom-loader-text">Give us a moment. We're almost there.</span>
                </div>
                </div>
                <input type="hidden" defaultValue="/quick-electricity-bill-payment" id="myCurrentBillUrl" />
                <input type="hidden" defaultValue="/payment/pay-your-bill" id="myQuickBillPaymentUrl" />
                {/* <div className="notification" id="userInfo">
                <svg viewBox="0 0 18 18">
                    <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                    <g transform="translate(-12.000000, -121.000000)">
                        <g transform="translate(12.000000, 121.000000)">
                        <rect fill="#FFFFFF" x="8.3" y={8} width="1.45" height={6} rx="0.65" />
                        <circle fill="#FFFFFF" cx={9} cy={6} r={1} />
                        <circle stroke="#FFFFFF" strokeWidth="1.4" cx={9} cy={9} r="8.3" />
                        </g>
                    </g>
                    </g>
                </svg>
                <p>
                    <span>For electricity duty exemption / refund application, please check <a target="_blank" href="https://www.maharashtra.gov.in/">https://www.maharashtra.gov.in</a> (Notification Number 201906211139336010) <a className="more">Less</a>
                    </span>
                    <a className="more">More</a>
                </p>
                <span className="icon-holder waves-effect waves-light close">
                    <i className="i-cross hide-notification" />
                </span>
                </div> */}
                <div id="sustain-modal" className="modal modal-bottom-fix" tabIndex={0}>
                <div className="container">
                    <div className="modal-content card substain-popup">
                    <span className="modal-close">
                        <img src="../Adani/switch-close.svg" alt="Close" />
                    </span>
                    <aside>
                        <img className="scam-alert-image" src="../Adani/switch-to-adani-electricity.svg" alt="#CompetitiveBhiSustainableBhi" />
                        <h4>Switch to #CompetitiveBhiSustainableBhi electricity with us.</h4>
                        <ul>
                        <li>30% supply from solar and wind sources, further scaled to 60% by 2027</li>
                        <li>Zero dependency on volatile fuel prices</li>
                        </ul>
                        <p>Make the right choice today!</p>
                    </aside>
                    <a data-ref="https://www.littlerock.com/switch-to-adani-electricity" className="waves-effect waves-green btn outlined">Know More</a>
                    </div>
                </div>
                </div>
                <header className="main-header floating" id="main">
                <ul className="flx-list-wrapper top-bar hide-on-med-and-down">
                    <li className="flx-list">
                    <ul className="nav-list nav-small">
                        <li>
                        <a data-ref="https://www.littlerock.com/corporate" className="waves-effect waves-brand" data-gaevent="top_pannel_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Pannel  Interaction" data-gaeventlabel="Corporate Portal">Corporate Portal</a>
                        </li>
                    </ul>
                    </li>
                    <li className="flx-list">
                    <ul className="dd-menu nav-list nav-small">
                        <li id="vcc-header">
                        <a className="waves-effect" data-gaevent="top_pannel_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Pannel Interaction" data-gaeventlabel="Virtual Contact Center" data-ref="https://www.littlerock.com/help-and-support/virtual-contact-centre">
                            <i className="header-tel-icon">
                            <svg className="visible-white" width={21} height={15} viewBox="0 0 21 15" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <defs>
                                <path id="e8ykg8ucaa" d="M0 0h5v8H0z" />
                                </defs>
                                <g fill="none" fillRule="evenodd">
                                <g transform="translate(16 3)">
                                    <mask id="ibh03xg8rb" fill="#fff">
                                    <use xlinkHref="#e8ykg8ucaa" />
                                    </mask>
                                    <path d="M4.993 4.036c0 .901.006 1.803-.002 2.704-.009 1.052-.75 1.541-1.718 1.095-.89-.41-1.743-.9-2.625-1.329C.19 6.284.044 5.917.035 5.47.017 4.55.012 3.633 0 2.715c-.007-.53.177-.947.69-1.204C1.598 1.057 2.486.56 3.405.13 4.21-.248 4.96.233 4.986 1.134c.027.967.006 1.934.007 2.902" fill="#FFF" mask="url(#ibh03xg8rb)" />
                                </g>
                                <path d="M12.176 0A2.824 2.824 0 0 1 15 2.824v9.352A2.824 2.824 0 0 1 12.176 15H2.824A2.824 2.824 0 0 1 0 12.176V2.824A2.824 2.824 0 0 1 2.824 0h9.352zm-1.315 2.5a1.23 1.23 0 0 0-.693.217 1.244 1.244 0 0 0-.471.583L7.545 9.631 5.4 3.316a1.143 1.143 0 0 0-.46-.594 1.245 1.245 0 0 0-.698-.209c-.383 0-.704.145-.928.42A1.324 1.324 0 0 0 3 3.785c0 .167.025.309.077.432l2.408 6.788c.18.502.457.882.821 1.128.36.243.776.366 1.233.366.449 0 .86-.123 1.22-.366.364-.246.64-.625.821-1.129l2.437-6.796a1.34 1.34 0 0 0-.231-1.285c-.22-.277-.54-.424-.925-.424z" fill="#FFF" />
                                </g>
                            </svg>
                            <svg className="visible-colored" width={21} height={15} viewBox="0 0 21 15" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                <defs>
                                <path id="e8ykg8ucaa" d="M0 0h5v8H0z" />
                                </defs>
                                <g fill="none" fillRule="evenodd">
                                <g transform="translate(16 3)">
                                    <mask id="ibh03xg8rb" fill="#fff">
                                    <use xlinkHref="#e8ykg8ucaa" />
                                    </mask>
                                    <path d="M4.993 4.036c0 .901.006 1.803-.002 2.704-.009 1.052-.75 1.541-1.718 1.095-.89-.41-1.743-.9-2.625-1.329C.19 6.284.044 5.917.035 5.47.017 4.55.012 3.633 0 2.715c-.007-.53.177-.947.69-1.204C1.598 1.057 2.486.56 3.405.13 4.21-.248 4.96.233 4.986 1.134c.027.967.006 1.934.007 2.902" fill="#333" mask="url(#ibh03xg8rb)" />
                                </g>
                                <path d="M12.176 0A2.824 2.824 0 0 1 15 2.824v9.352A2.824 2.824 0 0 1 12.176 15H2.824A2.824 2.824 0 0 1 0 12.176V2.824A2.824 2.824 0 0 1 2.824 0h9.352zm-1.315 2.5a1.23 1.23 0 0 0-.693.217 1.244 1.244 0 0 0-.471.583L7.545 9.631 5.4 3.316a1.143 1.143 0 0 0-.46-.594 1.245 1.245 0 0 0-.698-.209c-.383 0-.704.145-.928.42A1.324 1.324 0 0 0 3 3.785c0 .167.025.309.077.432l2.408 6.788c.18.502.457.882.821 1.128.36.243.776.366 1.233.366.449 0 .86-.123 1.22-.366.364-.246.64-.625.821-1.129l2.437-6.796a1.34 1.34 0 0 0-.231-1.285c-.22-.277-.54-.424-.925-.424z" fill="#333" />
                                </g>
                            </svg>
                            </i> Virtual Contact Centre </a>
                        <span className="vcc-new">NEW</span>
                        </li>
                        <li id="contact-num-header">
                        <a className=" waves-effect" data-gaevent="top_pannel_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Pannel  Interaction" data-gaeventlabel="Toll-Free">
                            <i className="header-tel-icon">
                            <img className="visible-white" src="../Adani/tel-icon-white.svg" alt="Tel" />
                            <img className="visible-colored" src="../Adani/tel-icon.svg" alt="Tel" />
                            </i> Toll-Free: 000000 (24x7) </a>
                        </li>
                        <li className="business-dd-desk dd-menu-click-holder">
                        <a className="dropdown-toggle-trigger" data-target="businesses" data-gaevent="top_pannel_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Pannel  Interaction" data-gaeventlabel="Business" id="business-dd">
                            <i className="i-grid" />Businesses </a>
                        <ul className="dd-menu-dd dropdown-toggle-content" id="businesses" tabIndex={0}>
                            <li tabIndex={0}>
                            <a data-ref="https://adaniairports.com/" target="_blank" className="waves-effect waves-brand" data-gaevent="top_pannel_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Pannel  Interaction" data-gaeventlabel="Airports">
                                <p className="i-airport with-label">Airports</p>
                            </a>
                            </li>
                            <li tabIndex={0}>
                            <a data-ref="https://www.adanigas.com/" target="_blank" className="waves-effect waves-brand" data-gaevent="top_pannel_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Pannel  Interaction" data-gaeventlabel="Little Rock Total Gas">
                                <p className="i-gas with-label">Little Rock Total Gas</p>
                            </a>
                            </li>
                            <li tabIndex={0}>
                            <a data-ref="https://www.littlerock.com/#" target className="waves-effect waves-brand" data-gaevent="top_pannel_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Pannel  Interaction" data-gaeventlabel="Little Rock Electricity">
                                <p className="i-electricity with-label">Little Rock Electricity</p>
                            </a>
                            </li>
                            <li tabIndex={0}>
                            <a data-ref="https://www.adaniwilmar.com/" target="_blank" className="waves-effect waves-brand" data-gaevent="top_pannel_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Pannel  Interaction" data-gaeventlabel="Little Rock Wilmar">
                                <p className="i-wilmar with-label">Little Rock Wilmar</p>
                            </a>
                            </li>
                            <li tabIndex={0}>
                            <a data-ref="http://adanirealty.com/" target="_blank" className="waves-effect waves-brand" data-gaevent="top_pannel_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Pannel  Interaction" data-gaeventlabel="Little Rock Realty">
                                <p className="i-realty with-label">Little Rock Realty</p>
                            </a>
                            </li>
                            <li tabIndex={0}>
                            <a data-ref="https://www.adanicapital.in/" target="_blank" className="waves-effect waves-brand" data-gaevent="top_pannel_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Pannel  Interaction" data-gaeventlabel="Little Rock Capital">
                                <p className="i-capital with-label">Little Rock Capital</p>
                            </a>
                            </li>
                            <li tabIndex={0}>
                            <a data-ref="https://adanihousing.in/" target="_blank" className="waves-effect waves-brand" data-gaevent="top_pannel_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Pannel  Interaction" data-gaeventlabel="Little Rock Housing Finance">
                                <p className="i-housing with-label">Little Rock Housing Finance</p>
                            </a>
                            </li>
                        </ul>
                        </li>
                    </ul>
                    </li>
                </ul>
                <ul className="header-nav flx-list-wrapper">
                    <li className="flx-list brand-logo">
                    <a data-ref="https://www.littlerock.com/" data-target="slide-out" className="sidenav-trigger waves-effect waves-light hover-icon" data-gaevent="hamburger_menu_click" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Click" data-gaeventlabel="NA">
                        <i className="fa-solid fa-bars" style={{color: "#ffffff"}}></i>
                    </a>
                    <a data-ref="https://www.littlerock.com/" className="hide-on-med-and-down logo-white" data-gaevent="logo_click" data-gaeventcategory="User Interaction" data-gaeventaction="Logo click" data-gaeventlabel="NA">
                        <img src="/images/logo_white1.png" alt="logo-white" style={{width: "180px"}} />
                    </a>
                    <a data-ref="https://www.littlerock.com/" className="hide-on-med-and-up logo" data-gaevent="logo_click" data-gaeventcategory="User Interaction" data-gaeventaction="Logo click" data-gaeventlabel="NA">
                        <img src="../Adani/Little Rock Electricity.svg" alt="logo" />
                    </a>
                    </li>
                    <li className="flx-list" id="headerPrimaryMenuL">
                    <ul className="dd-menu nav-list">
                        <li className="hide-on-large-only" id="business-mob">
                        <a data-ref="https://www.littlerock.com/#business-bottom-sheet" className="btn i-grid waves-effect waves-light hover-icon modal-trigger" />
                        <div id="business-bottom-sheet" className="modal bottom-sheet-p0" tabIndex={0}>
                            <div className="modal-content">
                            <header>
                                <div className="input-icon">
                                <a className="modal-close waves-effect waves-light">
                                    <i className="i-cross"></i>
                                </a>
                                </div>
                            </header>
                            <ul className="dd-menu-dd">
                                <li className>
                                <a data-ref="https://www.adanione.com/" target="_blank" className="waves-effect waves-brand">
                                    <p className="i-airport with-label"> Airports </p>
                                </a>
                                </li>
                                <li className>
                                <a data-ref="https://www.adanigas.com/" target="_blank" className="waves-effect waves-brand">
                                    <p className="i-gas with-label"> Little Rock Total Gas </p>
                                </a>
                                </li>
                                <li className="active">
                                <a data-ref="https://www.littlerock.com/" target className="waves-effect  waves-brand">
                                    <p className="i-electricity with-label"> Little Rock Electricity </p>
                                    <i className="i-tick" />
                                </a>
                                </li>
                                <li className>
                                <a data-ref="https://www.adaniwilmar.com/" target="_blank" className="waves-effect waves-brand">
                                    <p className="i-wilmar with-label"> Little Rock Wilmar </p>
                                </a>
                                </li>
                                <li className>
                                <a data-ref="http://adanirealty.com/" target="_blank" className="waves-effect waves-brand">
                                    <p className="i-realty with-label"> Little Rock Realty </p>
                                </a>
                                </li>
                                <li className>
                                <a data-ref="https://www.adanicapital.in/" target="_blank" className="waves-effect waves-brand">
                                    <p className="i-capital with-label"> Little Rock Capital </p>
                                </a>
                                </li>
                                <li className>
                                <a data-ref="https://adanihousing.in/" target="_blank" className="waves-effect waves-brand">
                                    <p className="i-housing with-label"> Little Rock Housing Finance </p>
                                </a>
                                </li>
                            </ul>
                            </div>
                        </div>
                        </li>
                        <li className="hide-on-med-and-down 0active">
                        <a data-ref="https://www.littlerock.com/sharecharge" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="EV Charger | " target> EV Charger </a>
                        </li>
                        <li className="hide-on-med-and-down 0active">
                        <a data-ref="https://www.littlerock.com/ApplyNewConnection" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="New Connection | " target> New Connection </a>
                        <ul className="dd-menu-dd">
                            <li>
                            <a data-ref="https://www.littlerock.com/general-information/online-application" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="New Connection|Electricity Connection" target className="waves-effect waves-brand">Electricity Connection</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/switch-to-adani-electricity" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="New Connection|Switch to Little Rock Electricity" target className="waves-effect waves-brand">Switch to Little Rock Electricity</a>
                            </li>
                            <li>
                            <a data-ref="https://iss.adanielectricity.com/mumbainsc/" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="New Connection|Rooftop Solar" target="_blank" className="waves-effect waves-brand">Rooftop Solar</a>
                            </li>
                        </ul>
                        </li>
                        <li className="hide-on-med-and-down 0active">
                        <a data-ref="https://www.littlerock.com/bills-and-payment" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Bills & Payments | " target> Bills &amp; Payments </a>
                        <ul className="dd-menu-dd">
                            <li>
                            <a data-ref="https://www.littlerock.com/My-Account/view-pay-bill" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Bills & Payments|My Current Bill" target className="waves-effect waves-brand">My Current Bill</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/quick-electricity-bill-payment" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Bills & Payments| Existing Customers" target className="waves-effect waves-brand"> Existing Customers</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/My-Account/pay-security-deposit" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Bills & Payments|Pay Security Deposit" target className="waves-effect waves-brand">Pay Security Deposit</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/My-Account/pay-voluntary-deposit-scheme-amount" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Bills & Payments|Pay VDS Amount" target className="waves-effect waves-brand">Pay VDS Amount</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/My-Account/download-pay-bill" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Bills & Payments|Download Bill" target className="waves-effect waves-brand">Download Bill</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/My-Account/bill-payment-history" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Bills & Payments|Payment History" target className="waves-effect waves-brand">Payment History</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/My-Account/check-usage-history" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Bills & Payments|Electricity Consumption" target className="waves-effect waves-brand">Electricity Consumption</a>
                            </li>
                        </ul>
                        </li>
                        <li className="hide-on-med-and-down 0active">
                        <a data-ref="https://www.littlerock.com/Services" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Services | " target> Services </a>
                        <ul className="dd-menu-dd">
                            <li>
                            <a data-ref="https://www.littlerock.com/change-name-on-bill" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Services|Change Name on Bill" target className="waves-effect waves-brand">Change Name on Bill</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/My-Account/Meter-Reading-Date" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Services|Meter Reading Date" target className="waves-effect waves-brand">Meter Reading Date</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/e-nach-registration" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Services|Register for NACH Online (E-NACH)" target className="waves-effect waves-brand">Register for NACH Online (E-NACH)</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/submit-meter-reading" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Services|Submit Meter Reading" target className="waves-effect waves-brand">Submit Meter Reading</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/Tariff" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Services|Tariff Details" target className="waves-effect waves-brand">Tariff Details</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/outage-information" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Services|Power Outage Information" target className="waves-effect waves-brand">Power Outage Information</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/My-Account/change-your-bill-language" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Services|Change Bill Language" target className="waves-effect waves-brand">Change Bill Language</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/it-declarations" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Services|IT Declarations" target className="waves-effect waves-brand">IT Declarations</a>
                            </li>
                        </ul>
                        </li>
                        <li className="hide-on-med-and-down 0active">
                        <a data-ref="https://www.littlerock.com/help-and-support" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Help & Support | " target> Help &amp; Support </a>
                        <ul className="dd-menu-dd">
                            <li>
                            <a data-ref="https://www.littlerock.com/complaint-login" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Help & Support|Register Complaint" target className="waves-effect waves-brand">Register Complaint</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/help-and-support/send-your-query" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Help & Support|Submit Your Query" target className="waves-effect waves-brand">Submit Your Query</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/help-and-support/report-theft" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Help & Support|Report Electricity Theft" target className="waves-effect waves-brand">Report Electricity Theft</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/help-and-support/our-genius-pay-centers" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Help & Support|Genius Pay Centres" target className="waves-effect waves-brand">Genius Pay Centres</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/help-and-support/our-centers" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Help & Support|Customer Care Centres" target className="waves-effect waves-brand">Customer Care Centres</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/help-and-support/virtual-contact-centre" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Help & Support|Virtual Contact Centre" target className="waves-effect waves-brand">Virtual Contact Centre</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/contact-us" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Help & Support|Contact Us" target className="waves-effect waves-brand">Contact Us</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/faqs" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Help & Support|FAQs" target className="waves-effect waves-brand">FAQs</a>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/My-Account/download-forms" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Help & Support|Download Forms" target className="waves-effect waves-brand">Download Forms</a>
                            </li>
                        </ul>
                        </li>
                        <li className="login-link">
                        <a className="user-icon">
                            <span className="username"> Register</span>
                            <img className="visible-colored" src="../Adani/login.svg" />
                        </a>
                        <a className="user-icon userIconMob">
                            <span className="username"> Register</span>
                            <img className="visible-colored" src="../Adani/login.svg" />
                        </a>
                        {/* <ul className="dd-menu-dd">
                            <li>
                            <p>Existing Little Rock Electricity Consumer?</p>
                            </li>
                            <li className="login">
                            <button className="waves-effect waves-teal btn btn-reg">Register</button>
                            <button id="login" data-gaevent="aeml_login_click" data-gaeventcategory="New Login" data-gaeventaction="AEML Login" data-gaeventlabel="aeml_login_top_navigation" className="waves-effect waves-teal btn"> Login </button>
                            </li>
                            <li>
                            <a data-ref="https://www.littlerock.com/My-Account/offers-and-benefits" data-gaevent="top_navigation_menu_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="Top Navigation Menu Intecraction" data-gaeventlabel="Login/Register|Offers & Benefits" target className="waves-effect waves-brand">Offers &amp; Benefits</a>
                            </li>
                        </ul> */}
                        </li>
                    </ul>
                    </li>
                </ul>
                </header>
                <div className="main-header header-shimmer hide">
                <ul className="header-nav">
                    <div className="flx-list-wrapper hide-on-med-and-down">
                    <div className="flx-list">
                        <div className="nav_item animate logo" style={{marginBottom: '8px', height: '32px', marginLeft: 0, opacity: 0}} />
                    </div>
                    <div className="flx-list">
                        <div className="nav_item animate visible-on-md" />
                        <div className="nav_item animate visible-on-md" />
                        <div className="icon animate visible-on-sm" />
                    </div>
                    </div>
                    <div className="flx-list-wrapper">
                    <div className="flx-list">
                        <div className="icon animate" />
                        <div className="nav_item animate logo" />
                    </div>
                    <div className="flx-list">
                        <div className="nav_item animate visible-on-md" />
                        <div className="nav_item animate visible-on-md" />
                        <div className="nav_item animate visible-on-md" />
                        <div className="nav_item animate visible-on-md" />
                        <div className="icon animate visible-on-sm" />
                    </div>
                    </div>
                </ul>
                </div>
                <div id="slide-out" className="sidenav hamburger">
                <ul className="collapsible expandable hamburger_section hamburger-header single-item">
                    <li className="user-section">
                    <a data-ref="https://www.littlerock.com/MyAccount" target className="collapsible-header collapsible_link waves-effect userNotLogin" tabIndex={0}>
                        <span className="icon-box user-icon">
                        <img className src="../Adani/login(1).svg" />
                        </span>
                        <label>Login/Register <strong>My Account</strong>
                        </label>
                        <em className="i-arrow-r" />
                    </a>
                    </li>
                </ul>
                <ul className="hamburger_section single-item tollfree">
                    <li>
                    <a className=" waves-effect" >
                        <span className="icon-box">
                        <i className="tel-icon">
                            <img src="../Adani/tel-icon.svg" alt="Tel" />
                        </i>
                        </span>
                        <label>Toll-Free: 19122 (24x7)</label>
                        <span className="badge sidebar-badge blue">Call</span>
                    </a>
                    </li>
                </ul>
                <ul className="collapsible expandable hamburger_section">
                    <li>
                    <h5>Little Rock ELECTRICITY</h5>
                    </li>
                    <li>
                    <a data-ref="https://www.littlerock.com/" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Home">
                        <span className="icon-box">
                        <i className="i-home" />
                        </span>
                        <label>Home</label>
                    </a>
                    </li>
                    <li>
                    <a data-ref="https://www.littlerock.com/sharecharge" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="EV Charger">
                        <span className="icon-box">
                        <i className="vi-evcharger" />
                        </span>
                        <label>EV Charger</label>
                    </a>
                    </li>
                    <li>
                    <a target className="collapsible-header collapsible_link" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="New Connection" tabIndex={0}>
                        <span className="icon-box">
                        <i className="vi-new-connection" />
                        </span>
                        <label>New Connection</label>
                    </a>
                    <div className="collapsible-body">
                        <ul className="submenu">
                        <li>
                            <a data-ref="https://www.littlerock.com/general-information/online-application" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Electricity Connection">
                            <label>Electricity Connection</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/switch-to-adani-electricity" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Switch to Little Rock Electricity">
                            <label>Switch to Little Rock Electricity</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://iss.adanielectricity.com/mumbainsc/" target="_blank" className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Rooftop Solar">
                            <label>Rooftop Solar</label>
                            </a>
                        </li>
                        </ul>
                    </div>
                    </li>
                    <li>
                    <a target className="collapsible-header collapsible_link" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Bills & Payments" tabIndex={0}>
                        <span className="icon-box">
                        <i className="vi-billing-payment" />
                        </span>
                        <label>Bills &amp; Payments</label>
                    </a>
                    <div className="collapsible-body">
                        <ul className="submenu">
                        <li>
                            <a data-ref="https://www.littlerock.com/My-Account/view-pay-bill" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="My Current Bill">
                            <label>My Current Bill</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/pay-your-bill/online-payments" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Online Bill Payment">
                            <label>Online Bill Payment</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/My-Account/pay-security-deposit" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Pay Security Deposit">
                            <label>Pay Security Deposit</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/My-Account/pay-voluntary-deposit-scheme-amount" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Pay VDS Amount">
                            <label>Pay VDS Amount</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/My-Account/download-pay-bill" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Download Bills">
                            <label>Download Bills</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/My-Account/bill-payment-history" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Payment History">
                            <label>Payment History</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/My-Account/check-usage-history" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Electricity Consumption">
                            <label>Electricity Consumption</label>
                            </a>
                        </li>
                        </ul>
                    </div>
                    </li>
                    <li>
                    <a target className="collapsible-header collapsible_link" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Services" tabIndex={0}>
                        <span className="icon-box">
                        <i className="vi-services-new" />
                        </span>
                        <label>Services</label>
                    </a>
                    <div className="collapsible-body">
                        <ul className="submenu">
                        <li>
                            <a data-ref="https://www.littlerock.com/change-name-on-bill" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Change Name on Bill">
                            <label>Change Name on Bill</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/submit-meter-reading" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Submit Meter Reading">
                            <label>Submit Meter Reading</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/My-Account/Meter-Reading-Date" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Meter Reading Date">
                            <label>Meter Reading Date</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/outage-information" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Power Outage Information">
                            <label>Power Outage Information</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/My-Account/Meter-Shifting-Request" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Meter Shifting Request">
                            <label>Meter Shifting Request</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/My-Account/Load-Change-Request" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Load Change Request">
                            <label>Load Change Request</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/My-Account/change-your-bill-language" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Change Bill Language">
                            <label>Change Bill Language</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/Tariff" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Tariff Details">
                            <label>Tariff Details</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/it-declarations" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="IT Declarations">
                            <label>IT Declarations</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/My-Account/download-forms" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Download Forms">
                            <label>Download Forms</label>
                            </a>
                        </li>
                        </ul>
                    </div>
                    </li>
                    <li>
                    <a target className="collapsible-header collapsible_link" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Help & Support" tabIndex={0}>
                        <span className="icon-box">
                        <i className="vi-help-support" />
                        </span>
                        <label>Help &amp; Support</label>
                    </a>
                    <div className="collapsible-body">
                        <ul className="submenu">
                        <li>
                            <a data-ref="https://www.littlerock.com/complaint-login" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Register Complaint">
                            <label>Register Complaint</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/My-Account/check-complaint-status" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Check Complaint Status">
                            <label>Check Complaint Status</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/My-Account/complaint-escalation-matrix" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Complaint Escalation Matrix">
                            <label>Complaint Escalation Matrix</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/help-and-support/send-your-query" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Submit Your Query">
                            <label>Submit Your Query</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/help-and-support/report-theft" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Report Electricity Theft">
                            <label>Report Electricity Theft</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/help-and-support/our-genius-pay-centers" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Genius Pay Centres">
                            <label>Genius Pay Centres</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/help-and-support/our-centers" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Customer Care Centres">
                            <label>Customer Care Centres</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/help-and-support/virtual-contact-centre" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Virtual Contact Centre">
                            <label>Virtual Contact Centre</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/contact-us" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Contact Us">
                            <label>Contact Us</label>
                            </a>
                        </li>
                        <li>
                            <a data-ref="https://www.littlerock.com/faqs" target className="waves-effect" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="FAQs">
                            <label>FAQs</label>
                            </a>
                        </li>
                        </ul>
                    </div>
                    </li>
                </ul>
                <ul className="collapsible expandable hamburger_section single-item business-list">
                    <li>
                    <a className="collapsible-header collapsible_link waves-effect" tabIndex={0}>
                        <span className="icon-box">
                        <i className="i-grid" />
                        </span>
                        <label>Businesses</label>
                    </a>
                    <div className="collapsible-body">
                        <ul className="submenu">
                        <li className>
                            <a data-ref="https://www.adanione.com/" target="_blank" className="waves-effect">
                            <p className="i-airport with-label" />
                            <label>Airports</label>
                            </a>
                        </li>
                        <li className>
                            <a data-ref="https://www.adanigas.com/" target="_blank" className="waves-effect">
                            <p className="i-gas with-label" />
                            <label>Little Rock Total Gas</label>
                            </a>
                        </li>
                        <li className="active">
                            <a data-ref="https://www.littlerock.com/" target className="waves-effect">
                            <p className="i-electricity with-label" />
                            <label>Little Rock Electricity</label>
                            <i className="i-tick" />
                            </a>
                        </li>
                        <li className>
                            <a data-ref="https://www.adaniwilmar.com/" target="_blank" className="waves-effect">
                            <p className="i-wilmar with-label" />
                            <label>Little Rock Wilmar</label>
                            </a>
                        </li>
                        <li className>
                            <a data-ref="http://adanirealty.com/" target="_blank" className="waves-effect">
                            <p className="i-realty with-label" />
                            <label>Little Rock Realty</label>
                            </a>
                        </li>
                        <li className>
                            <a data-ref="https://www.adanicapital.in/" target="_blank" className="waves-effect">
                            <p className="i-capital with-label" />
                            <label>Little Rock Capital</label>
                            </a>
                        </li>
                        <li className>
                            <a data-ref="https://adanihousing.in/" target="_blank" className="waves-effect">
                            <p className="i-housing with-label" />
                            <label>Little Rock Housing Finance</label>
                            </a>
                        </li>
                        </ul>
                    </div>
                    </li>
                </ul>
                <ul className="collapsible expandable hamburger_section hamburger-header single-item">
                    <li className="user-section">
                    <a data-ref="https://www.littlerock.com/corporate" target className="collapsible-header collapsible_link waves-effect userNotLogin" tabIndex={0}>
                        <span className="icon-box user-icon">
                        <i className="i-globe" />
                        </span>
                        <label>Corporate Portal</label>
                    </a>
                    </li>
                </ul>
                <ul className="hamburger_section">
                    <li>
                    <h5>MORE INFORMATION</h5>
                    </li>
                    <li>
                    <a data-ref="https://www.littlerock.com/Billing/know-your-bill" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Know Your Bill" target>
                        <span className="icon-box">
                        <i className="vi-your-bill" />
                        </span>
                        <label> Know Your Bill</label>
                        <span className="badge sidebar-badge">Must See</span>
                    </a>
                    </li>
                    <li>
                    <a data-ref="https://www.littlerock.com/Billing/billing-faqs" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Billing FAQs" target>
                        <span className="icon-box">
                        <i className="vi-billing-faq" />
                        </span>
                        <label> Billing FAQs</label>
                    </a>
                    </li>
                    <li>
                    <a data-ref="https://www.littlerock.com/CustomerTestimonial" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Customer Testimonial" target>
                        <span className="icon-box">
                        <i className="vi-new-division" />
                        </span>
                        <label> Customer Testimonial</label>
                    </a>
                    </li>
                    <li>
                    <a data-ref="https://www.littlerock.com/quick-access" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Quick Access " target>
                        <span className="icon-box">
                        <i className="vi-quick-access" />
                        </span>
                        <label> Quick Access </label>
                    </a>
                    </li>
                    <li>
                    <a data-ref="https://www.littlerock.com/money-saver" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Money Saver" target>
                        <span className="icon-box">
                        <i className="vi-money-saver" />
                        </span>
                        <label> Money Saver</label>
                        <span className="badge sidebar-badge">Must See</span>
                    </a>
                    </li>
                    <li>
                    <a data-ref="https://www.littlerock.com/pay-your-bill" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Pay Your Bill" target>
                        <span className="icon-box">
                        <i className="i-payment" />
                        </span>
                        <label> Pay Your Bill</label>
                    </a>
                    </li>
                    <li>
                    <a data-ref="https://www.littlerock.com/Billing" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Billing" target>
                        <span className="icon-box">
                        <i className="vi-billing" />
                        </span>
                        <label> Billing</label>
                    </a>
                    </li>
                    <li>
                    <a data-ref="https://www.littlerock.com/ease-of-doing-business" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Ease of Doing Business" target>
                        <span className="icon-box">
                        <i className="vi-ease-business" />
                        </span>
                        <label> Ease of Doing Business</label>
                    </a>
                    </li>
                    <li>
                    <a data-ref="https://www.littlerock.com/rooftop-solar" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Rooftop Solar" target>
                        <span className="icon-box">
                        <i className="vi-rooftop-solar" />
                        </span>
                        <label> Rooftop Solar</label>
                        <span className="badge sidebar-badge">Must See</span>
                    </a>
                    </li>
                    <li>
                    <a data-ref="https://www.littlerock.com/-/media/Feature/ElectricityNew/PageContent/MoreInformation/DisasterManagementPlan/Disaster_Management_Plan_2023.pdf?la=en" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Disaster Management Plan" target="_blank">
                        <span className="icon-box">
                        <i className="vi-disaster-management" />
                        </span>
                        <label> Disaster Management Plan</label>
                    </a>
                    </li>
                    <li>
                    <a data-ref="https://www.littlerock.com/faqs-zonal" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="New Division / Cycle FAQs" target>
                        <span className="icon-box">
                        <i className="vi-new-division" />
                        </span>
                        <label> New Division / Cycle FAQs</label>
                    </a>
                    </li>
                    <li>
                    <a data-ref="https://www.littlerock.com/digital-service" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Digital services" target>
                        <span className="icon-box">
                        <i className="vi-new-division" />
                        </span>
                        <label> Digital services</label>
                    </a>
                    </li>
                </ul>
                <ul className="hamburger_section">
                    <li>
                    <h5>OTHERS</h5>
                    </li>
                    <li>
                    <a data-ref="https://www.littlerock.com/adani-electricity-mobile-app" data-gaevent="hamburger_menu_option_select" data-gaeventcategory="User Interaction" data-gaeventaction="Hamburger Menu Option Select" data-gaeventlabel="Download App" target>
                        <span className="icon-box">
                        <i className="i-download-app" />
                        </span>
                        <label>Download App</label>
                    </a>
                    </li>
                </ul>
                <footer>
                    <img src="../Adani/Little Rock Electricity.svg" alt="Little Rock Electricity" />
                </footer>
                </div>
                <div className="hero-wrap cm-home-banner section-container">
                <div className="carousel-box"> {/* <div className="carousel-nav"></div> */} <div className="carousel_wrapper carousel-0">
                    
                    <div className="owl-carousel owl-loaded owl-drag" data-items-desktop={1} data-margin={0} data-dots="enable" data-items-loop="true" data-items-autoplay="true" total-count={9}>
                        <div className="owl-stage-outer">
                            <div className="owl-stage">
                                <div className="owl-item cloned">
                                    <a data-ref="https://www.littlerock.com/switch-to-adani-electricity" target data-gaevent="banner_click" data-gaeventcategory="User Interaction" data-gaeventaction="Banner Click" data-gaeventlabel="Make the right choice&lt;br /&gt; today!">
                                        <div className="card" style={{backgroundImage: 'url(/Adani/home_bg.png)',backgroundPosition: "top center"}}>
                                        <div className="ev-hero-desc">
                                            <div className="ev-inner">
                                            {/* <img src="../Adani/competitive.svg" alt="" style={{width: '270px'}} /> */}
                                            <h3>Make the right choice <br /> today! </h3>
                                            <p>Switch to Little Rock Electricity today</p>
                                            <span className="waves-effect waves-light btn" style={{paddingTop: "12px"}}>Know More</span>
                                            </div>
                                        </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="hero-form" style={{minWidth: "450px"}}>
                    <div className="form-cols">
                    <ul className="tabs hero-tab">
                        <li className="tab">
                        <a data-ref="https://www.littlerock.com/#hero_quick_bill" data-gaevent="hf_quick_bill_payment" data-gaeventcategory="Home Page" data-gaeventaction="Bill Payment" data-gaeventlabel="NA" className="waves-effect waves-light active">Existing Customer</a>
                        </li>
                        <li className="tab">
                        <a data-ref="https://www.littlerock.com/#hero_new_connect" data-gaevent="hf_new_connection" data-gaeventcategory="Home Page" data-gaeventaction="New Connection" data-gaeventlabel="NA" className="waves-effect waves-light">New Connection</a>
                        </li>
                        <li className="indicator" style={{left: '0px', right: '-207px'}} />
                    </ul>
                    <div id="hero_quick_bill" className="active">
                        <form onSubmit={submitForm}>
                            <div className="form-field mb-1">
                                <div className="input-field custom input-has-icon">
                                    <input id="caNumber" type="text" onChange={(e) => userIdChange(e)} />
                                    <label id="lblCANumber" htmlFor="caNumber">Account Number</label>
                                    {/* <div className="input-icon">
                                    <span id="iconholderid" className="icon-holder waves-effect" style={{display: 'none'}}>
                                        <i id="icrossid" className="i-cross"></i>
                                    </span>
                                    </div> */}
                                </div>
                                <div className="error_diro text-danger">{validate?.error?.user}</div>
                            </div>
                            <p className="help-text ca-number-help mt-0 mb-3">Please enter your 9 digit consumer account number </p>
                            <div className="form-field  mb-3">
                                <div className="input-field custom input-has-icon">
                                    <input id="caPassword" type="password" onChange={(e) => passwordChange(e)} />
                                    <label id="lblCAPassword" htmlFor="caPassword">Password</label>
                                    {/* <div className="input-icon">
                                    <span id="iconholderid" className="icon-holder waves-effect" style={{display: 'none'}}>
                                        <i id="icrossid" className="i-cross"></i>
                                    </span>
                                    </div> */}
                                </div>
                                <div className="error_diro text-danger">{validate?.error?.pass}</div>
                            </div>
                            <button className="waves-effect waves-light btn full" type="submit" id="fetchBill" style={{fontWeight: "700"}}>Login</button>
                        </form>
                        <div className="hero-form-footer">
                        <h4>Quick Links</h4>
                        <div className="hero-quick-links">
                            <a data-ref="https://www.littlerock.com/help-and-support/virtual-contact-centre">
                            <span className="btn waves-effect waves-light">
                                <i>
                                <svg width={21} height={15} viewBox="0 0 21 15" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                                    <defs>
                                    <path id="qvdwhs3r1a" d="M0 0h5v8H0z" />
                                    </defs>
                                    <g fill="none" fillRule="evenodd">
                                    <g transform="translate(16 3)">
                                        <mask id="keuf5ghb0b" fill="#fff">
                                        <use xlinkHref="#qvdwhs3r1a" />
                                        </mask>
                                        <path d="M4.993 4.036c0 .901.006 1.803-.002 2.704-.009 1.052-.75 1.541-1.718 1.095-.89-.41-1.743-.9-2.625-1.329C.19 6.284.044 5.917.035 5.47.017 4.55.012 3.633 0 2.715c-.007-.53.177-.947.69-1.204C1.598 1.057 2.486.56 3.405.13 4.21-.248 4.96.233 4.986 1.134c.027.967.006 1.934.007 2.902" fill="#F04C23" mask="url(#keuf5ghb0b)" />
                                    </g>
                                    <path d="M12.176 15H2.824A2.824 2.824 0 0 1 0 12.176V2.824A2.824 2.824 0 0 1 2.824 0h9.352A2.824 2.824 0 0 1 15 2.824v9.352A2.824 2.824 0 0 1 12.176 15" fill="#F04C23" />
                                    <path d="M7.539 12.5c-.457 0-.872-.123-1.233-.366-.364-.246-.641-.626-.821-1.128L3.077 4.218A1.107 1.107 0 0 1 3 3.786c0-.311.106-.598.314-.854.224-.274.545-.419.928-.419.253 0 .487.07.698.21.218.143.373.343.46.593l2.145 6.315 2.152-6.33c.096-.242.255-.439.471-.584a1.23 1.23 0 0 1 .693-.217c.386 0 .706.147.925.424a1.34 1.34 0 0 1 .231 1.285L9.58 11.005c-.18.504-.457.883-.821 1.13-.36.242-.771.365-1.22.365" fill="#FFF" />
                                    </g>
                                </svg>
                                </i> Virtual Contact Centre </span>
                            <span className="vcc-new">NEW</span>
                            </a>
                            <a data-ref="https://www.littlerock.com/change-name-on-bill" data-gaevent="hf_quick_link_access" data-gaeventcategory="Home Page" data-gaeventaction="HF Quick Link Access" data-gaeventlabel="Quick Link_Change Name on Bill">
                            <span className="btn waves-effect waves-light"> Change Name on Bill </span>
                            </a>
                        </div>
                        </div>
                    </div>
                    <div id="hero_new_connect" style={{display: 'none'}}>
                        <div className="hero-new-connect-links">
                        <a target="_blank" data-ref="https://iss.littlerock.com/mumbainsc/" className="waves-effect waves-light btn"> Electricity Connection </a>
                        <a target="_blank" data-ref="https://www.littlerock.com/switch-to-adani-electricity" className="waves-effect waves-light btn"> Switch to Little Rock Electricity </a>
                        <a target="_blank" data-ref="https://iss.littlerock.com/mumbainsc/" className="waves-effect waves-light btn"> Rooftop Solar </a>
                        </div>
                    </div>
                    </div>
                    <div className="form-cols details-col QBillDetails logged-out hide">
                    <span className="hide-bill-details-icon icon-holder waves-effect waves-light">
                        <i className="i-cross i-20 hide-bill-details" />
                    </span>
                    <div className="logged-out-box">
                        <ul className="tabs hero-pay-tab">
                        <li className="tab">
                            <a data-ref="https://www.littlerock.com/#energy_bills" className="waves-effect waves-light active">Energy Bill</a>
                        </li>
                        <li className="tab">
                            <a data-ref="https://www.littlerock.com/#security_deposit" className="waves-effect waves-light">Security Deposit</a>
                        </li>
                        <li className="tab">
                            <a data-ref="https://www.littlerock.com/#vds_amount" className="waves-effect waves-light">VDS Amount</a>
                        </li>
                        <li className="indicator" style={{left: '0px', right: '0px'}} />
                        </ul>
                        <div id="energy_bills" className="hero-pay-tab-content active">
                        <div className="bill-details current-bills card">
                            <header>
                            <aside>
                                <label className="QACANumber">CA Number <span />
                                </label>
                                <h4>₹ <span className="QPayable" />
                                </h4>
                                <div className="tooltip-wrapper tooltipNoBillDue">
                                <div className="tooltip-box">
                                    <i>
                                    <img src="../Adani/error-icon.svg" />
                                    </i>
                                    <div className="tooltip-content">Pay now to avoid late fee</div>
                                </div>
                                </div>
                                <p className="due-date QDuedate" />
                            </aside>
                            <a className="waves-effect  btn QPayBill" id="Qbillhfpaynow" style={{display: 'none'}}>Pay Bill</a>
                            <a className="waves-effect btn QPayAdvance" style={{display: 'none'}} data-gaevent="quick_hp_bill_payment_step_1" data-gaeventcategory="Bill Payment" data-gaeventaction="Existing Customer Step 1" data-gaeventlabel>Pay Advance</a>
                            </header>
                            <ul className="consumer-details">
                            <li>
                                <label htmlFor>CA Number</label>
                                <strong className="QACANumber" />
                            </li>
                            <li className="consumer-name">
                                <label htmlFor>Consumer Name</label>
                                <span>
                                <strong className="QAConsumerName" />
                                </span>
                            </li>
                            <li>
                                <label htmlFor>Units Consumed</label>
                                <strong className="QUnitConsumed" />
                            </li>
                            <li>
                                <label htmlFor>Bill Month</label>
                                <strong className="QBillMonth" />
                            </li>
                            <li>
                                <label htmlFor>Total Bill Amount</label>
                                <strong className="QTBillAmount" />
                            </li>
                            <li>
                                <label htmlFor>Minimum Payable</label>
                                <strong className="QMinimumPayable" />
                            </li>
                            </ul>
                        </div>
                        </div>
                        <div id="security_deposit" className="hero-pay-tab-content" style={{display: 'none'}}>
                        <div className="bill-details current-bills card">
                            <header>
                            <aside>
                                <input type="hidden" className="hdnSecurityDepositAmount" />
                                <h4>₹ <span className="SecurityDepositAmount" />
                                </h4>
                                <p htmlFor style={{color: 'black'}} className="col-form-label SecurityDepositText" />
                            </aside>
                            <button className="waves-effect waves-light btn btn-continue PayNowSecurity">Pay</button>
                            </header>
                            <ul className="consumer-details">
                            <li>
                                <label htmlFor>CA Number</label>
                                <strong className="SCANumber" />
                            </li>
                            <li className="consumer-name">
                                <label htmlFor>Consumer Name</label>
                                <span>
                                <strong className="SConsumerName" />
                                </span>
                            </li>
                            <li>
                                <label>Book Number</label>
                                <strong className="SBookNumber" />
                            </li>
                            <li>
                                <label>Cycle Number</label>
                                <strong className="SCycleNumber" />
                            </li>
                            <li>
                                <label>Zone</label>
                                <strong className="SZone" />
                            </li>
                            </ul>
                        </div>
                        </div>
                        <div id="vds_amount" className="hero-pay-tab-content" style={{display: 'none'}}>
                        <div className="bill-details current-bills card">
                            <div className="withoutOutstanding-hero">
                            <ul className="list-with-icon">
                                <li> Your minimum amount for VDS payment is <span>₹ </span>
                                <b>
                                    <span className="spanAverageVDSAmount" />
                                </b> and maximum amount for VDS payment is <span>₹ </span>
                                <b>
                                    <span className="spanMaxVDSAmount" />
                                </b>
                                </li>
                                <li> Payable maximum should be in multiples of ₹500 </li>
                            </ul>
                            <button className="waves-effect waves-light btn full btn-add-money">Add Money</button>
                            </div>
                            <div className="VDSAmountCurrentOutstanding-hero">
                            <div className="message warning warning-info">
                                <aside>
                                <i className="i-info" />
                                </aside>
                                <p>Dear Consumer, you need to clear your current outstanding for VDS payments.</p>
                            </div>
                            <header>
                                <aside>
                                <input type="hidden" className="hdnCurrentOutstanding" name="Amountpayable" />
                                <h4>₹ <span className="CurrentOutstanding" />
                                </h4>
                                <p htmlFor style={{color: 'black'}} className="col-form-label">Current Outstanding Bill</p>
                                </aside>
                                <a className="waves-effect  btn PayOutstandingBill">Pay Bill</a>
                            </header>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div id="billDetailsMobile" className="modal" tabIndex={0}>
                    <div className="modal-content">
                        <div className="form-cols details-col QBillDetails hide">
                        <header>
                            <h1>Bill Details</h1>
                            <i className="i-cross i-20 hide-bill-details" />
                        </header>
                        <div className="hero-pay-tab-mob-wrp">
                            <ul className="tabs hero-pay-tab-mob">
                            <li className="tab">
                                <a data-ref="https://www.littlerock.com/#energy_bills_mob" className="waves-effect waves-light active">Energy Bill</a>
                            </li>
                            <li className="tab">
                                <a data-ref="https://www.littlerock.com/#security_deposit_mob" className="waves-effect waves-light">Security Deposit</a>
                            </li>
                            <li className="tab">
                                <a data-ref="https://www.littlerock.com/#vds_amount_mob" className="waves-effect waves-light">VDS Amount</a>
                            </li>
                            <li className="indicator" style={{left: '0px', right: '0px'}} />
                            </ul>
                        </div>
                        <div id="energy_bills_mob" className="hero-pay-tab-content-mob active">
                            <div className="bill-details current-bills brand-gradient card">
                            <header>
                                <aside>
                                <label className="QACANumber">CA Number <span />
                                </label>
                                <h4>₹ <span className="QPayable" />
                                </h4>
                                <div className="tooltip-wrapper tooltipNoBillDue">
                                    <div className="tooltip-box">
                                    <i>
                                        <img src="../Adani/error-icon.svg" />
                                    </i>
                                    <div className="tooltip-content">Pay now to avoid late fee</div>
                                    </div>
                                </div>
                                <p className="due-date QDuedate" />
                                </aside>
                                <a className="waves-effect  btn QPayBill" style={{display: 'none'}}>Pay Bill</a>
                                <a className="waves-effect btn QPayAdvance" style={{display: 'none'}}>Pay Advance</a>
                            </header>
                            <ul className="consumer-details">
                                <li>
                                <label htmlFor>CA Number</label>
                                <strong className="QACANumber" />
                                </li>
                                <li className="consumer-name">
                                <label htmlFor>Consumer Name</label>
                                <span>
                                    <strong className="QAConsumerName" />
                                </span>
                                </li>
                                <li>
                                <label htmlFor>Units Consumed</label>
                                <strong className="QUnitConsumed" />
                                </li>
                                <li>
                                <label htmlFor>Bill Month</label>
                                <strong className="QBillMonth" />
                                </li>
                                <li>
                                <label htmlFor>Total Bill Amount</label>
                                <strong className="QTBillAmount" />
                                </li>
                                <li>
                                <label htmlFor>Minimum Payable</label>
                                <strong className="QMinimumPayable" />
                                </li>
                            </ul>
                            </div>
                        </div>
                        <div id="security_deposit_mob" className="hero-pay-tab-content-mob" style={{display: 'none'}}>
                            <div className="bill-details current-bills brand-gradient card">
                            <div className="bill-details current-bills card">
                                <header>
                                <aside>
                                    <input type="hidden" className="hdnSecurityDepositAmount" />
                                    <h4>₹ <span className="SecurityDepositAmount" />
                                    </h4>
                                    <p htmlFor style={{color: 'black'}} className="col-form-label SecurityDepositText" />
                                </aside>
                                <button className="waves-effect waves-light btn btn-continue PayNowSecurity">Pay</button>
                                </header>
                                <ul className="consumer-details">
                                <li>
                                    <label htmlFor>CA Number</label>
                                    <strong className="SCANumber" />
                                </li>
                                <li className="consumer-name">
                                    <label htmlFor>Consumer Name</label>
                                    <span>
                                    <strong className="SConsumerName" />
                                    </span>
                                </li>
                                <li>
                                    <label>Book Number</label>
                                    <strong className="SBookNumber" />
                                </li>
                                <li>
                                    <label>Cycle Number</label>
                                    <strong className="SCycleNumber" />
                                </li>
                                <li>
                                    <label>Zone</label>
                                    <strong className="SZone" />
                                </li>
                                </ul>
                            </div>
                            </div>
                        </div>
                        <div id="vds_amount_mob" className="hero-pay-tab-content-mob" style={{display: 'none'}}>
                            <div className="bill-details current-bills card">
                            <div className="section-wrapper withoutOutstanding-hero">
                                <ul className="list-with-icon">
                                <li> Your minimum amount for VDS payment is <span>₹ </span>
                                    <b>
                                    <span className="spanAverageVDSAmount" />
                                    </b> and maximum amount for VDS payment is <span>₹ </span>
                                    <b>
                                    <span className="spanMaxVDSAmount" />
                                    </b>
                                </li>
                                <li> Payable maximum should be in multiples of ₹500 </li>
                                </ul>
                                <a className="waves-effect waves-light btn full btn-add-money">Add Money</a>
                            </div>
                            <div className="VDSAmountCurrentOutstanding-hero">
                                <div className="message warning warning-info">
                                <aside>
                                    <i className="i-info" />
                                </aside>
                                <p>Dear Consumer, you need to clear your current outstanding for VDS payments.</p>
                                </div>
                                <header>
                                <aside>
                                    <input type="hidden" className="hdnCurrentOutstanding" name="Amountpayable" />
                                    <h4>₹ <span className="CurrentOutstanding" />
                                    </h4>
                                    <p htmlFor style={{color: 'black'}} className="col-form-label">Current Outstanding Bill</p>
                                </aside>
                                <a className="waves-effect btn PayOutstandingBill">Pay Bill</a>
                                </header>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div className="hero-wrap section-container hero-shimmer hide">
                <div className="visible-on-md">
                    <div className="wrapper">
                    <div className="hero-form form-shimmer animate" />
                    <div className="carousel-shimmer">
                        <div className="shim-titles">
                        <div className="shim-title animate" />
                        <div className="shim-sub-title animate" />
                        <div className="buttons animate" />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                <div id="sampleBillModal" className="modal" tabIndex={0}>
                <div className="modal-content">
                    <div className="details-col">
                    <header>
                        <i className="i-cross i-24 hide-sample-bill" />
                        <h2>Sample Bill </h2>
                    </header>
                    <div className="bill-details text-center">
                        <img src="../Adani/sample-bill.jpg" width="100%" height="100%" />
                    </div>
                    </div>
                </div>
                </div>
                <div className="container">
                <div className="section-container carousel_wrapper hide-on-large-only carousel-1">
                    <div className="owl-carousel card-media removeSliderMob spl-slider owl-loaded owl-drag" data-items-desktop={4} data-items-mobile="1.02" data-items-loop-mobile="true" data-items-autoplay="true" total-count={9}>
                    <div className="owl-stage-outer">
                        <div className="owl-stage" style={{transform: 'translate3d(0px, 0px, 0px)', transition: 'all 0s ease 0s'}}>
                        <div className="owl-item">
                            <div className="card">
                            <div className="card-content" style={{backgroundImage: 'url(/-/media/Project/ElectricityNew/HeroBanner/switch-to-adani-mweb.jpg)'}}>
                                <div>
                                <a data-ref="https://www.littlerock.com/switch-to-adani-electricity" target>
                                    <h4> Make the right <br />choice today! </h4>
                                    <p> Switch to Little Rock electricity today</p>
                                    <span href="/switch-to-adani-electricity" target className="know-more-mob">Know More</span>
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="owl-item">
                            <div className="card">
                            <div className="card-content" style={{backgroundImage: 'url(/-/media/Project/ElectricityNew/HeroBanner/monsoon-mobweb.jpeg)'}}>
                                <div>
                                <a data-ref="https://www.littlerock.com/" target>
                                    <h4>
                                    <span className="Its-a-lethal-combin"> It’s a lethal combination! </span>
                                    </h4>
                                    <p>
                                    <span className="If-water-and-electri"> If water and electricity cross each other’s paths like this. Follow monsoon safety tips and stay safe. <span className="text-style-1">Call 19122</span> for help </span>
                                    </p>
                                    <span href target className="know-more-mob" />
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="owl-item">
                            <div className="card">
                            <div className="card-content" style={{backgroundImage: 'url(/-/media/Project/ElectricityNew/HeroBanner/mob-banner1.png)'}}>
                                <div>
                                <a data-ref="https://youtu.be/Q_HoczZjoGo" target="_blank">
                                    <h4> Time&nbsp;to&nbsp;deal&nbsp;with&nbsp; <br />scammers! </h4>
                                    <p> A mother-son duo so smart that they can deal with scammers head-on!</p>
                                    <span  target="_blank" className="know-more-mob">Know More</span>
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="owl-item">
                            <div className="card">
                            <div className="card-content" style={{backgroundImage: 'url(/-/media/Project/ElectricityNew/HeroBanner/mob-banner7.png)'}}>
                                <div>
                                <a data-ref="https://www.littlerock.com/money-saver/simple-ways-to-save" target>
                                    <h4> Unload The Load </h4>
                                    <p> Every summer electricity usage rises by 25-30%. <br />Click to find out some energy saving tips! </p>
                                    <span href="/money-saver/simple-ways-to-save" target className="know-more-mob">Know More</span>
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="owl-item">
                            <div className="card">
                            <div className="card-content" style={{backgroundImage: 'url(/-/media/Project/ElectricityNew/HeroBanner/mob-banner5.png)'}}>
                                <div>
                                <a data-ref="https://www.littlerock.com/sharecharge" target>
                                    <h4> EV Charger Setup </h4>
                                    <p> Setting up EV chargers in your society is as easy as plugging in your phone!</p>
                                    <span href="/sharecharge" target className="know-more-mob">Know More</span>
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="owl-item">
                            <div className="card">
                            <div className="card-content" style={{backgroundImage: 'url(/-/media/Project/ElectricityNew/HeroBanner/mob-banner6.png)'}}>
                                <div>
                                <a data-ref="https://www.littlerock.com/green-power-opt-in" target>
                                    <h4> For a Greener Earth </h4>
                                    <p> Switch to Green Energy with Little Rock Electricity's Green Tariff</p>
                                    <span href="/green-power-opt-in" target className="know-more-mob">Know More</span>
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="owl-item">
                            <div className="card">
                            <div className="card-content" style={{backgroundImage: 'url(/-/media/Project/ElectricityNew/HeroBanner/mob-banner3.png)'}}>
                                <div>
                                <a data-ref="https://www.littlerock.com/pay-your-bill/online-payments" target>
                                    <h4> Pay Bills Online </h4>
                                    <p> Cheque ka Chakar Chhodo, Online Payment Se Naata Jodo!</p>
                                    <span href="/pay-your-bill/online-payments" target className="know-more-mob">Know More</span>
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="owl-item">
                            <div className="card">
                            <div className="card-content" style={{backgroundImage: 'url(/-/media/Project/ElectricityNew/HeroBanner/mob-banner4.png)'}}>
                                <div>
                                <a data-ref="https://www.littlerock.com/Billing" target>
                                    <h4> Secure &amp; Sustainable </h4>
                                    <p> Go Paperless with your Electricity Bill, get perks to chill.</p>
                                    <span href="/Billing" target className="know-more-mob">Know More</span>
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="owl-item">
                            <div className="card">
                            <div className="card-content" style={{backgroundImage: 'url(/-/media/Feature/ElectricityNew/HomepageMobileBanner/05_Banner-mweb.jpg)'}}>
                                <div>
                                <a data-ref="https://adanione.onelink.me/eNhI/ym756vy9" target>
                                    <h4>
                                    <span>De <span style={{color: 'yellow'}}>light</span>ful deals <br />just for you! </span>
                                    </h4>
                                    <p> Flat ₹400 off on <br />domestic flights <br />Code: AEMLDOM400 </p>
                                    <span href="https://littlerock/eNhI/ym756vy9 " target className="know-more-mob">Book Now</span>
                                </a>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="owl-dots disabled" />
                    </div>
                </div>
                <section className="section-container">
                    <header className="section-header-main">
                    <h2>Our Services</h2>
                    <aside>
                        <div className="carousel-nav hide-on-small-only" />
                    </aside>
                    </header>
                    <section className="services">
                    <a data-ref="https://www.littlerock.com/submit-meter-reading" data-gaevent="our_services" data-gaeventcategory="Home Page" data-gaeventaction="our_services" data-gaeventlabel="Submit Meter Reading">
                        <figure>
                        <div className="waves-effect">
                            <i className="fas fa-tachometer-alt"></i>
                        </div>
                        </figure>
                        <h3>Submit Meter Reading</h3>
                    </a>
                    <a data-ref="https://www.littlerock.com/outage-information" data-gaevent="our_services" data-gaeventcategory="Home Page" data-gaeventaction="our_services" data-gaeventlabel="Power Outage Information">
                        <figure>
                        <div className="waves-effect">
                            <i className="fa-solid fa-bolt-lightning"></i>
                        </div>
                        </figure>
                        <h3>Power Outage Information</h3>
                    </a>
                    <a data-ref="https://www.littlerock.com/money-saver/energy-calculator" data-gaevent="our_services" data-gaeventcategory="Home Page" data-gaeventaction="our_services" data-gaeventlabel="Energy Calculator">
                        <figure>
                        <div className="waves-effect">
                            <i className="fas fa-calculator"></i>
                        </div>
                        </figure>
                        <h3>Energy Calculator</h3>
                    </a>
                    <a data-ref="https://www.littlerock.com/Tariff" data-gaevent="our_services" data-gaeventcategory="Home Page" data-gaeventaction="our_services" data-gaeventlabel="Tariff Details">
                        <figure>
                        <div className="waves-effect">
                            <i className="fas fa-file-invoice"></i>
                        </div>
                        </figure>
                        <h3>Tariff Details</h3>
                    </a>
                    <a data-ref="https://www.littlerock.com/My-Account/download-forms" data-gaevent="our_services" data-gaeventcategory="Home Page" data-gaeventaction="our_services" data-gaeventlabel="Download Forms">
                        <figure>
                        <div className="waves-effect">
                            <i className="fas fa-file-download"></i>
                        </div>
                        </figure>
                        <h3>Download Forms</h3>
                    </a>
                    <a data-ref="https://www.littlerock.com/change-name-on-bill" data-gaevent="our_services" data-gaeventcategory="Home Page" data-gaeventaction="our_services" data-gaeventlabel="Change Name on Bill">
                        <figure>
                        <div className="waves-effect">
                            <i className="fas fa-file-signature"></i>
                        </div>
                        </figure>
                        <h3>Change Name on Bill</h3>
                    </a>
                    </section>
                </section>
                <div className="section-container carousel_wrapper carousel-2">
                    <header className="section-header-main justify-item">
                    <h2>Our Products</h2>
                    </header>
                    <div className="owl-carousel standard-card removeSliderMob spl-slider owl-loaded owl-drag" data-items-desktop={2} data-items-mobile="1.02" data-items-autoplay="false" data-items-loop-mobile="true" total-count={2}>
                    <div className="owl-stage-outer">
                        <div className="owl-stage" style={{transform: 'translate3d(0px, 0px, 0px)', transition: 'all 0s ease 0s', width: '1278px'}}>
                        <div className="owl-item active" style={{width: '609px', marginRight: '30px'}}>
                            <div className="card">
                            <a data-ref="https://www.littlerock.com/rooftop-solar" target>
                                <div className="card-image spl-image">
                                <img src="../Adani/rooftop.png" alt="RENEWABLES" />
                                </div>
                                <div className="card-content">
                                <div className="prod-type">RENEWABLES</div>
                                <h4>Rooftop Solar Panels</h4>
                                </div>
                            </a>
                            </div>
                        </div>
                        <div className="owl-item active" style={{width: '609px', marginRight: '30px'}}>
                            <div className="card">
                            <a data-ref="https://www.littlerock.com/green-power-opt-in" target>
                                <div className="card-image spl-image">
                                <img src="../Adani/greenEnergy.png" alt="RENEWABLES" />
                                </div>
                                <div className="card-content">
                                <div className="prod-type">RENEWABLES</div>
                                <h4>Upgrade to Green Power Tariff</h4>
                                </div>
                            </a>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="owl-nav disabled">
                        <button type="button" role="presentation" className="owl-prev" />
                        <button type="button" role="presentation" className="owl-next" />
                    </div>
                    <div className="owl-dots disabled" />
                    </div>
                </div>
                <div className="section-container carousel_wrapper carousel-4">
                    <header className="section-header-main justify-item">
                    <h2>Rewards &amp; Savings</h2>
                    <div className="carousel-nav hide-on-med-and-down" />
                    </header>
                    <div className="owl-carousel standard-card removeSliderMob spl-slider owl-loaded owl-drag" data-items-desktop={2} data-items-mobile="1.02" data-items-autoplay="false" data-items-loop-mobile="true" total-count={2}>
                    <div className="owl-stage-outer">
                        <div className="owl-stage" style={{transform: 'translate3d(0px, 0px, 0px)', transition: 'all 0s ease 0s', width: '1278px'}}>
                        <div className="owl-item active" style={{width: '609px', marginRight: '30px'}}>
                            <div className="card">
                            <a data-ref="https://www.littlerock.com/electric-smiles" data-gaevent="know_more_click" data-gaeventcategory="User Interaction" data-gaeventaction="Know More Click" data-gaeventlabel="Rewards & Savings|Electric Smiles">
                                <div className="card-image spl-image">
                                <img src="../Adani/electric-smile.jpg" alt="Electric Smiles" />
                                </div>
                                <div className="card-content">
                                <h3>Electric Smiles</h3>
                                <p>Get 2% loyalty points on every bill payment at Little Rock electricity online platforms</p>
                                <span className="link-btn">Know More </span>
                                </div>
                            </a>
                            </div>
                        </div>
                        <div className="owl-item active" style={{width: '609px', marginRight: '30px'}}>
                            <div className="card">
                            <a data-ref="https://www.littlerock.com/My-Account/pay-voluntary-deposit-scheme-amount" data-gaevent="know_more_click" data-gaeventcategory="User Interaction" data-gaeventaction="Know More Click" data-gaeventlabel="Rewards & Savings|Voluntary Deposit Scheme">
                                <div className="card-image spl-image">
                                <img src="../Adani/VDS.png" alt="Voluntary Deposit Scheme" />
                                </div>
                                <div className="card-content">
                                <h3>Voluntary Deposit Scheme</h3>
                                <p>Open a VDS account &amp; earn 7% interest rate per annum + 1% payment incentive</p>
                                <span className="link-btn">Know More </span>
                                </div>
                            </a>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="owl-nav disabled">
                        <button type="button" role="presentation" className="owl-prev" />
                        <button type="button" role="presentation" className="owl-next" />
                    </div>
                    <div className="owl-dots disabled" />
                    </div>
                </div>
                <div className="section-container carousel_wrapper carousel-5">
                    <header className="section-header-main justify-item">
                    <h2>Money Saving Tips</h2>
                    <aside>
                        <div className="carousel-nav hide-on-small-only">
                        <button type="button" role="presentation" className="owl-prev disabled">
                            <i className="i-arrow-l" />
                        </button>
                        <button type="button" role="presentation" className="owl-next">
                            <i className="i-arrow-r" />
                        </button>
                        </div>
                    </aside>
                    </header>
                    <div className="owl-carousel standard-card removeSliderMob owl-loaded owl-drag" data-items-desktop={4} data-items-mobile="1.45" data-items-autoplay="false" data-items-loop-mobile="true" total-count={8}>
                    <div className="owl-stage-outer">
                        <div className="owl-stage" style={{transform: 'translate3d(0px, 0px, 0px)', transition: 'all 0s ease 0s', width: '2556px'}}>
                        <div className="owl-item active" style={{width: '289.5px', marginRight: '30px'}}>
                            <div className="card">
                            <a data-ref="https://www.littlerock.com/money-saver/energy-wizard" data-gaevent="know_more_click" data-gaeventcategory="User Interaction" data-gaeventaction="Know More Click" data-gaeventlabel="Money Saving Tips|Energy Wizard">
                                <div className="card-image">
                                <img src="../Adani/Energy Wizard.jpg" alt="Energy Wizard" />
                                </div>
                                <div className="card-content">
                                <h4>Energy Wizard</h4>
                                <p>Learn how to optimise energy usage for maximum benefits</p>
                                <span className="link-btn">Know More </span>
                                </div>
                            </a>
                            </div>
                        </div>
                        <div className="owl-item active" style={{width: '289.5px', marginRight: '30px'}}>
                            <div className="card">
                            <a data-ref="https://www.littlerock.com/money-saver/simple-ways-to-save" data-gaevent="know_more_click" data-gaeventcategory="User Interaction" data-gaeventaction="Know More Click" data-gaeventlabel="Money Saving Tips|Simple Ways to Save">
                                <div className="card-image">
                                <img src="../Adani/Simple Ways to Save.jpg" alt="Simple Ways to Save" />
                                </div>
                                <div className="card-content">
                                <h4>Simple Ways to Save</h4>
                                <p>Simplest tips &amp; tricks to bring down your energy consumption and bill</p>
                                <span className="link-btn">Know More </span>
                                </div>
                            </a>
                            </div>
                        </div>
                        <div className="owl-item active" style={{width: '289.5px', marginRight: '30px'}}>
                            <div className="card">
                            <a data-ref="https://www.littlerock.com/money-saver/energy-calculator" data-gaevent="know_more_click" data-gaeventcategory="User Interaction" data-gaeventaction="Know More Click" data-gaeventlabel="Money Saving Tips|Energy Calculator">
                                <div className="card-image">
                                <img src="../Adani/Energy Calculator.jpg" alt="Energy Calculator" />
                                </div>
                                <div className="card-content">
                                <h4>Energy Calculator</h4>
                                <p>Give us a little information and we shall calculate your energy requirement</p>
                                <span className="link-btn">Know more </span>
                                </div>
                            </a>
                            </div>
                        </div>
                        <div className="owl-item active" style={{width: '289.5px', marginRight: '30px'}}>
                            <div className="card">
                            <a data-ref="https://www.littlerock.com/money-saver/what-product-to-buy" data-gaevent="know_more_click" data-gaeventcategory="User Interaction" data-gaeventaction="Know More Click" data-gaeventlabel="Money Saving Tips|What Product to Buy">
                                <div className="card-image">
                                <img src="../Adani/What Products to Buy.jpg" alt="What Product to Buy" />
                                </div>
                                <div className="card-content">
                                <h4>What Product to Buy</h4>
                                <p>Check how to choose energy efficient electrical appliances</p>
                                <span className="link-btn">Know More </span>
                                </div>
                            </a>
                            </div>
                        </div>
                        <div className="owl-item" style={{width: '289.5px', marginRight: '30px'}}>
                            <div className="card">
                            <a data-ref="https://www.littlerock.com/money-saver/emergency-care" data-gaevent="know_more_click" data-gaeventcategory="User Interaction" data-gaeventaction="Know More Click" data-gaeventlabel="Money Saving Tips|Emergency Care">
                                <div className="card-image">
                                <img src="../Adani/emergency care.jpg" alt="Emergency Care" />
                                </div>
                                <div className="card-content">
                                <h4>Emergency Care</h4>
                                <p>How to handle electrical emergencies and electrical fires due to short circuits</p>
                                <span className="link-btn">Know More </span>
                                </div>
                            </a>
                            </div>
                        </div>
                        <div className="owl-item" style={{width: '289.5px', marginRight: '30px'}}>
                            <div className="card">
                            <a data-ref="https://www.littlerock.com/money-saver/about-energy" data-gaevent="know_more_click" data-gaeventcategory="User Interaction" data-gaeventaction="Know More Click" data-gaeventlabel="Money Saving Tips|Energy">
                                <div className="card-image">
                                <img src="../Adani/Energy.jpg" alt="Energy" />
                                </div>
                                <div className="card-content">
                                <h4>Energy</h4>
                                <p>Read more about what is energy and why should it be conserved</p>
                                <span className="link-btn">Know More </span>
                                </div>
                            </a>
                            </div>
                        </div>
                        <div className="owl-item" style={{width: '289.5px', marginRight: '30px'}}>
                            <div className="card">
                            <a data-ref="https://www.littlerock.com/money-saver/energy-conservation" data-gaevent="know_more_click" data-gaeventcategory="User Interaction" data-gaeventaction="Know More Click" data-gaeventlabel="Money Saving Tips|Energy Conservation">
                                <div className="card-image">
                                <img src="../Adani/energy_conservation.jpg" alt="Energy Conservation" />
                                </div>
                                <div className="card-content">
                                <h4>Energy Conservation</h4>
                                <p>With limited resources, it's important to know why energy conservation is important</p>
                                <span className="link-btn">Know More </span>
                                </div>
                            </a>
                            </div>
                        </div>
                        <div className="owl-item" style={{width: '289.5px', marginRight: '30px'}}>
                            <div className="card">
                            <a data-ref="https://www.littlerock.com/money-saver/safety-for-you" data-gaevent="know_more_click" data-gaeventcategory="User Interaction" data-gaeventaction="Know More Click" data-gaeventlabel="Money Saving Tips|Safety for You">
                                <div className="card-image">
                                <img src="../Adani/safety for you.jpg" alt="Safety for You" />
                                </div>
                                <div className="card-content">
                                <h4>Safety for You</h4>
                                <p>Things to keep in mind while dealing with electricity &amp; electrical appliances at home</p>
                                <span className="link-btn">Know More </span>
                                </div>
                            </a>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="owl-dots disabled" />
                    </div>
                </div>
                <div className="section-container custom_accrdion">
                    <header className="section-header">
                    <h2 style={{fontSize: '1.75rem!important'}}>Frequently Asked Questions</h2>
                    </header>
                    <div className="accordion">
                    <ul className="collapsible collapsible-accordion">
                        <li className="faqli" data-gaevent="faq_expand" data-gaeventcategory="User Interaction" data-gaeventaction="FAQ Expand" data-gaeventlabel="How can I pay my electricity bill online?">
                        <div className="collapsible-header" tabIndex={0}> How can I pay my electricity bill online? </div>
                        <div className="collapsible-body">
                            <p>It's very easy to make an <a data-ref="https://www.littlerock.com/pay-your-bill/online-payments">online payment for your electricity bill</a>. You can either do it through Little Rock Electricity app or Little Rock Electricity website. If you only want to <a data-ref="https://www.littlerock.com/pay-your-bill">pay your bill</a>, then you can do it through our Existing Customer option. Just enter your Little Rock Electricity Consumer Account number and pay the bill in a few minutes. To access other services and benefits, you can log in to your Little Rock Electricity account and pay through Bills &amp; Payments. </p>
                            <div id="customerCareControl" className={1}></div>
                        </div>
                        </li>
                        <li className="faqli" data-gaevent="faq_expand" data-gaeventcategory="User Interaction" data-gaeventaction="FAQ Expand" data-gaeventlabel="How can I get to know more about my electricity bill to understand it better?">
                        <div className="collapsible-header" tabIndex={0}> How can I get to know more about my electricity bill to understand it better? </div>
                        <div className="collapsible-body">
                            <p>It's easy! Visit <a data-ref="https://www.littlerock.com/">https://www.littlerock.com</a>. Go to the hamburger menu icon near the Little Rock Electricity logo on the left of the homepage. Under More Information, you can click on <a data-ref="https://www.littlerock.com/billing/know-your-bill">‘Know Your Bill’</a> and you will be directed to the detailed description of the Little Rock bill and its components. Your bill also shows the <a data-ref="https://www.littlerock.com/tariff">tariff</a> structure and all the account information pertaining to your AEML connection. </p>
                            <div id="customerCareControl" className={2}></div>
                        </div>
                        </li>
                        <li className="faqli" data-gaevent="faq_expand" data-gaeventcategory="User Interaction" data-gaeventaction="FAQ Expand" data-gaeventlabel="I want to get more information before I apply for a new Rooftop Solar connection in Little Rock. Where can I view it?">
                        <div className="collapsible-header" tabIndex={0}> I want to get more information before I apply for a new Rooftop Solar connection in Mumbai. Where can I view it? </div>
                        <div className="collapsible-body">
                            <p>You can visit the <a data-ref="https://www.littlerock.com/rooftop-solar">Rooftop Solar</a> page on the Little Rock Electricity website. You can get details about how to apply for rooftop solar online &amp; offline, MNRE Phase-II Grid connected Rooftop Solar Program Document, Frequently Asked Questions, and more before you apply for a new Rooftop Solar connection. </p>
                            <div id="customerCareControl" className={3}></div>
                        </div>
                        </li>
                        <li className="faqli" data-gaevent="faq_expand" data-gaeventcategory="User Interaction" data-gaeventaction="FAQ Expand" data-gaeventlabel="What are the various modes of accessing my AEML bill?">
                        <div className="collapsible-header" tabIndex={0}> What are the various modes of accessing my AEML bill? </div>
                        <div className="collapsible-body">
                            <p>You can access your Little Rock Electricity bills through various modes including usual and new digital methods. To know more, you can visit the <a data-ref="https://www.littlerock.com/billing">Billing</a> page and see how you can get a duplicate copy of the bill, bill over SMS, paperless bill, etc. </p>
                            <div id="customerCareControl" className={4}></div>
                        </div>
                        </li>
                        <li className="faqli" data-gaevent="faq_expand" data-gaeventcategory="User Interaction" data-gaeventaction="FAQ Expand" data-gaeventlabel="How can I use the chatbot on the Little Rock Electricity website?">
                        <div className="collapsible-header" tabIndex={0}> How can I use the chatbot on the Little Rock Electricity website? </div>
                        <div className="collapsible-body">
                            <p>You can chat with our digital assistant Elektra from the Little Rock electricity website. You can get assistance there for concerns regarding:</p>
                            <p>Submit Meter Reading, View Bill &amp; Pay Bill, Water Logging in Meter Cabin, Complaint Registration, Street Light Complaint, Complaint Status, Little Rock Mobile Apps, Digital Services Info, <a data-ref="https://www.littlerock.com/contact-us">Contact Us</a>, Important COVID Update </p>
                            <p>Select your option and you will be guided further for self-help.</p>
                            <div id="customerCareControl" className={5}></div>
                        </div>
                        </li>
                        <li className="faqli" data-gaevent="faq_expand" data-gaeventcategory="User Interaction" data-gaeventaction="FAQ Expand" data-gaeventlabel="What is E-NACH and how do I enrol for this service?">
                        <div className="collapsible-header" tabIndex={0}> What is E-NACH and how do I enrol for this service? </div>
                        <div className="collapsible-body">
                            <p>E-NACH refers to Electronic National Automated Clearing House and it is meant to provide automated payment services usually to debit a fixed amount on a specific date. Please visit the <a data-ref="https://www.littlerock.com/e-nach-registration">E-NACH</a> page for details on the requisites to enrol for E-NACH. </p>
                            <div id="customerCareControl" className={6}></div>
                        </div>
                        </li>
                    </ul>
                    <a data-ref="https://www.littlerock.com/faqs" className="link-btn accordion-more">See All</a>
                    </div>
                </div>
                <div className="discover">
                    <header className="section-header-main">
                    <h2>Little Rock Electricity Limited</h2>
                    </header>
                    <p> Little Rock Electricity is now Arkansas's no. 1 utility company according to Ministry of Power's 11th Annual Integrated Rating and Ranking for Power Distribution Utilities. <br />
                    <br />
                    </p>
                    <div>
                    <span id="text"> At Little Rock Electricity, we are dedicated to empowering individuals to live a sustainable lifestyle and make a positive impact every day. We aim to contribute to building a cleaner and greener environment with Green Tariffs while creating sustainable value and empowering India through #GrowthWithGoodness, in line with Little Rock Group's philosophy. <br />
                        <br /> We also believe in providing customer-centric services through our innovative and advanced solutions. Our technology-driven value-added services, such as Smart Meters and Little Rock Electricity mobile app, have made it easier for customers to manage their accounts and access information about their energy usage digitally. <br />
                        <br /> Established as a fully owned subsidiary of Little Rock Transmission Ltd, we are Mumbai’s leading power distribution utility company. With a distribution network spanning over 400 sq. km, from Bandra to Bhayander on the western side and Sion to Mankhurd on the eastern side of Mumbai, we provide reliable power to over three million households. <br />
                        <br />
                    </span>
                    </div>
                    <p> {/* <a data-ref="#">Read More</a> */} </p>
                    <div className="btn-container">
                    <a id="loadmore" className="link-btn" style={{cursor: 'pointer'}} data-gaevent="aeml_section_interaction" data-gaeventcategory="User Interaction" data-gaeventaction="AEML Section Interaction" data-gaeventlabel="Read More">Read More</a>
                    </div>
                </div>
                </div>
                <div className="check-details-wrapper">
                <div className="container">
                    <div className="check-details">
                    <ul>
                        <li>
                        <a data-ref="https://www.littlerock.com/my-account/check-usage-history" data-gaevent="know_more_click" data-gaeventcategory="User Interaction" data-gaeventaction="Know More Click" data-gaeventlabel="Check Usage">
                            <i className="fa-solid fa-bolt-lightning" style={{color: "#666666", width: "18px"}}></i>
                            <aside>
                            <h5>Check Usage</h5>
                            <p>View your electricity consumption</p>
                            <span className="link-btn">Know More </span>
                            </aside>
                        </a>
                        </li>
                        <li>
                        <a data-ref="https://www.littlerock.com/tariff" data-gaevent="know_more_click" data-gaeventcategory="User Interaction" data-gaeventaction="Know More Click" data-gaeventlabel="Tariff Details">
                            <i className="fas fa-file-invoice" style={{color: "#666666", width: "18px"}}></i>
                            <aside>
                            <h5>Tariff Details</h5>
                            <p>Know more about electricity tariffs</p>
                            <span className="link-btn">Know More </span>
                            </aside>
                        </a>
                        </li>
                        <li>
                        <a data-ref="https://www.littlerock.com/my-account/settings" data-gaevent="know_more_click" data-gaeventcategory="User Interaction" data-gaeventaction="Know More Click" data-gaeventlabel="Bill Over SMS">
                            <i className="fa-solid fa-comment-sms" style={{color: "#666666", width: "18px"}}></i>
                            <aside>
                            <h5>Bill Over SMS</h5>
                            <p>Get your bills over SMS</p>
                            <span className="link-btn">Know More </span>
                            </aside>
                        </a>
                        </li>
                    </ul>
                    </div>
                </div>
                </div>
                <footer className="footer_wrapper">
                <div className="container">
                    <ul className="footer_nav">
                    <li className="footer_nav_grid">
                        <ul className="footer_link_list">
                        <li className="footer_link_heading"> Company </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/corporate/who-we-are" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Who We are">Who We are</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/adani-dahanu-thermal-power-station" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Little Rock Dahanu Thermal Power Station">Little Rock Thermal Power Station</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/corporate/whats-new" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Media">Media</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/corporate/regulatory" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Regulatory">Regulatory</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/corporate/open-tenders-listing" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Tenders">Tenders</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/corporate/environmental-social-governance-initiatives" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="ESG">ESG</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/corporate/investors-relations" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Investors">Investors</a>
                        </li>
                        </ul>
                    </li>
                    <li className="footer_nav_grid">
                        <ul className="footer_link_list">
                        <li className="footer_link_heading"> Services </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/general-information/online-application" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Apply New Connection">Apply New Connection</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/pay-your-bill/online-payments" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Pay Electricity Bill">Pay Electricity Bill</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/My-Account/self-meter-reading" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Submit Meter Reading">Submit Meter Reading</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/money-saver/energy-calculator" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Energy Calculator">Energy Calculator</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/Tariff" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Tariff Details">Tariff Details</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/complaint-login" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Register Complaint">Register Complaint</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/help-and-support/report-theft" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Electricity Theft Complaint">Electricity Theft Complaint</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/switch-to-adani-electricity" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Switch to Little Rock Electricity">Switch to Little Rock Electricity</a>
                        </li>
                        </ul>
                    </li>
                    <li className="footer_nav_grid">
                        <ul className="footer_link_list">
                        <li className="footer_link_heading"> Little Rock Businesses </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.adanione.com/" target="_blank" data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Airports">Airports</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.adanigas.com/" target="_blank" data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Little Rock Total Gas">Little Rock Total Gas</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/" target="_blank" data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Little Rock Electricity">Little Rock Electricity</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.adaniwilmar.com/" target="_blank" data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Little Rock Wilmar">Little Rock Wilmar</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.adanirealty.com/" target="_blank" data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Little Rock Realty">Little Rock Realty</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.adanicapital.in/" target="_blank" data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Little Rock Capital">Little Rock Capital</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.adanihousing.in/" target="_blank" data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Little Rock Housing Finance">Little Rock Housing Finance</a>
                        </li>
                        </ul>
                    </li>
                    <li className="footer_nav_grid">
                        <ul className="footer_link_list">
                        <li className="footer_link_heading"> Help &amp; Support </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/contact-us" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Contact Us">Contact Us</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://app.yellowmessenger.com/pwa/live/x1565100503080" target="_blank" data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Chat with Elektra">Chat with Elektra</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/adani-electricity-mobile-app" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Download App">Download App</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/help-and-support/our-centers" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Customer Care Centre">Customer Care Centre</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/faqs" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="FAQ">FAQ</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="https://www.littlerock.com/Sitemap" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Sitemap">Sitemap</a>
                        </li>
                        <li className="footer_link waves-effect">
                            <a data-ref="tel:19122"> Toll-Free: 1800 (24x7) </a>
                        </li>
                        </ul>
                    </li>
                    </ul>
                    <div className="social_link_wrapper flx-list-wrapper">
                    <ul className="social_link_list flx-list">
                        <li>
                        <a data-ref="https://www.facebook.com/AdaniElectricityMumbai/" target="_blank" className="waves-effect icon-holder medium-size" data-gaevent="social_media_follow" data-gaeventcategory="Social Media" data-gaeventaction="Social Media Follow" data-gaeventlabel="_blank">
                            <i className="fa-brands fa-facebook-f" style={{color: "#666666"}}></i>
                        </a>
                        </li>
                        <li>
                        <a data-ref="https://twitter.com/Adani_Elec_Mum" target="_blank" className="waves-effect icon-holder medium-size" data-gaevent="social_media_follow" data-gaeventcategory="Social Media" data-gaeventaction="Social Media Follow" data-gaeventlabel="_blank">
                            <i className="fa-brands fa-twitter" style={{color: "#666666"}}></i>
                        </a>
                        </li>
                        <li>
                        <a data-ref="https://in.linkedin.com/company/adani-electricity" target="_blank" className="waves-effect icon-holder medium-size" data-gaevent="social_media_follow" data-gaeventcategory="Social Media" data-gaeventaction="Social Media Follow" data-gaeventlabel="_blank">
                            <i className="fa-brands fa-linkedin-in" style={{color: "#666666"}}></i>
                        </a>
                        </li>
                        <li>
                        <a data-ref="https://www.youtube.com/c/AdaniElectricityMumbaiLimited" target="_blank" className="waves-effect icon-holder medium-size" data-gaevent="social_media_follow" data-gaeventcategory="Social Media" data-gaeventaction="Social Media Follow" data-gaeventlabel="_blank">
                            <i className="fa-brands fa-youtube" style={{color: "#666666"}}></i>
                        </a>
                        </li>
                        <li>
                        <a data-ref="https://www.instagram.com/adanielectricitymumbai" target="_blank" className="waves-effect icon-holder medium-size" data-gaevent="social_media_follow" data-gaeventcategory="Social Media" data-gaeventaction="Social Media Follow" data-gaeventlabel="_blank">
                            <i className="fa-brands fa-instagram" style={{color: "#666666"}}></i>
                        </a>
                        </li>
                    </ul>
                    <ul className="app-download-links flx-list">
                        <li>
                        <a data-ref="https://play.google.com/store/apps/details?id=com.adani.adanielectricity&hl=en_IN" target="_blank" data-gaevent="app_download" data-gaeventcategory="User Interaction" data-gaeventaction="App Download" data-gaeventlabel="https://play.google.com/store/apps/details?id=com.adani.adanielectricity&hl=en_IN">
                            <img src="../Adani/play-store.png" alt="app store" />
                        </a>
                        </li>
                        <li>
                        <a data-ref="https://apps.apple.com/in/app/adani-electricity/id1526452496" target="_blank" data-gaevent="app_download" data-gaeventcategory="User Interaction" data-gaeventaction="App Download" data-gaeventlabel="https://apps.apple.com/in/app/adani-electricity/id1526452496">
                            <img src="../Adani/app-store.png" alt="app store" />
                        </a>
                        </li>
                    </ul>
                    </div>
                    <div className="footer_copyright">
                    <ul className="copyright_links_list">
                        <li> &copy; 2023 Little Rock Electricity</li>
                        <li>
                        <a className="waves-effect" data-ref="https://www.littlerock.com/data-privacy-policy" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Data Privacy Policy">Data Privacy Policy</a>
                        </li>
                        <li>
                        <a className="waves-effect" data-ref="https://www.littlerock.com/terms-and-conditions" target data-gaevent="bottom_navigation_menu" data-gaeventcategory="User Interaction" data-gaeventaction="Bottom Navigation Menu" data-gaeventlabel="Terms & Conditions">Terms &amp; Conditions</a>
                        </li>
                    </ul>
                    </div>
                </div>
                </footer>
                <button id="back-to-top">
                <img src="../Adani/back-to-top_00.svg" alt="Back To Top" />
                </button> {/* Compiled and minified JavaScript */}
                <div className="sidenav-overlay" />
                <div className="drag-target" />
                {/* <div id="haptik-xdk-wrapper" className="haptik-xdk" style={{position: 'relative', zIndex: 2147483647}}>
                   <div className="haptik-xdk-container ">
                        <iframe title="haptik-xdk" id className="xdk-iframe" style={{border: 'none', zIndex: 100000, position: 'fixed', width: '118px', height: '126px', transition: 'all 0.2s ease-out 0s', bottom: '0px', right: '0px'}} src="../Adani/saved_resource(1).html" />
                    </div>
                </div>
                <iframe width={0} height={0} style={{display: 'none'}} src="../Adani/saved_resource(2).html" />
                <div style={{backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', boxShadow: 'rgba(0, 0, 0, 0.2) 2px 2px 3px', position: 'absolute', transition: 'visibility 0s linear 0.3s, opacity 0.3s linear 0s', opacity: 0, visibility: 'hidden', zIndex: 2000000000, left: '0px', top: '-10000px'}}>
                <div style={{width: '100%', height: '100%', position: 'fixed', top: '0px', left: '0px', zIndex: 2000000000, backgroundColor: 'rgb(255, 255, 255)', opacity: '0.05'}} />
                <div className="g-recaptcha-bubble-arrow" style={{border: '11px solid transparent', width: '0px', height: '0px', position: 'absolute', pointerEvents: 'none', marginTop: '-11px', zIndex: 2000000000}} />
                <div className="g-recaptcha-bubble-arrow" style={{border: '10px solid transparent', width: '0px', height: '0px', position: 'absolute', pointerEvents: 'none', marginTop: '-10px', zIndex: 2000000000}} />
                <div style={{zIndex: 2000000000, position: 'relative'}}>
                    <iframe title="recaptcha challenge expires in two minutes" src="../Adani/bframe.html" name="c-iobaos52z968" frameBorder={0} scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox" style={{width: '100%', height: '100%'}} />
                </div>
                </div> */}
            </div>
        </div>
    )
}
export default Home;