import './App.css';
import { useState } from 'react';
import { BrowserRouter } from "react-router-dom";
import { Context } from "./context";
import Layout from "./views/Layout";

function App() {
  const [userDetail, setUserDetail] = useState(null);
  return (
    <div className="App">
      <BrowserRouter>
				<Context.Provider
          value={
            {
              userDetail,
              setUserDetail,
            }
          }>
					<Layout />
				</Context.Provider>
			</BrowserRouter>
    </div>
  );
}

export default App;
