import React, { useEffect, useContext } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Context } from "../context";
import Header from "./Header";
import Home from "./Home";
import Footer from "./Footer";
import Dashboard from "./Dashboard";
const Layout = (props) => {
    const {
		setUserDetail
	} = useContext(Context);
    const navigate = useNavigate();
    const cleanupLocalStorage = () => {
        const storedData = localStorage.getItem('utils_user');
        if (storedData) {
            const parsedData = JSON.parse(storedData);
            setUserDetail(parsedData);
            if (parsedData.timestamp <= Date.now()) {
                localStorage.removeItem('utils_user');
                navigate("/");
            } else {
                navigate("/dashboard");
            }
        } else {
            navigate("/");
        }
    }
    useEffect(() => {
        cleanupLocalStorage();
    }, []);
    return (
        <>
            {/* <Header /> */}
            <Routes>
				<Route path="/" exact={true} element={<Home />} />
				<Route path="/dashboard" element={<Dashboard />} />
            </Routes>
            {/* <Footer /> */}
        </>
    )
}
export default Layout;